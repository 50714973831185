<template>
  <div class="bg--horoscope">
    <div class="container" v-if="horoscope">
      <div class="header">
        <router-link to="/" class="header__logo"></router-link>
        <div class="header-block">
          <router-link to="/horoscope" class="header-block__name">
            Мои гороскопы
          </router-link>
        </div>
      </div>
      <div class="horoscope">
        <h2 class="horoscope__tit-small">
          {{horoscope.name}}
        </h2>
        <div class="horoscope-info" v-html="horoscope.description.replace(/&nbsp;/g, ' ')">
        </div>
        <div class="horoscope-info__img-block">
          <img src="@/assets/images/system.png" srcset="@/assets/images/system@2x.png 2x"
            alt="" class="horoscope-info__img">
          <div class="horoscopes-block__symbol-block"
            :class="' horoscopes-block__symbol-block--3'"
          >
            <img :src="img"
              alt="" class="horoscopes-block__symbol"
              :class="'horoscopes-block__symbol--' + horoscope.zodiac.id"
            >
          </div>
        </div>
      </div>
      <footer-block />
    </div>
  </div>
</template>

<script>
import aries from '@/assets/images/aries@2x.png';
import taurus from '@/assets/images/taurus@2x.png';
import gemini from '@/assets/images/gemini@2x.png';
import cancer from '@/assets/images/cancer@2x.png';
import leo from '@/assets/images/leo@2x.png';
import virgo from '@/assets/images/virgo@2x.png';
import libra from '@/assets/images/libra@2x.png';
import scorpio from '@/assets/images/scorpio@2x.png';
import sagittarius from '@/assets/images/sagittarius@2x.png';
import capricorn from '@/assets/images/capricorn@2x.png';
import aquarius from '@/assets/images/aquarius@2x.png';
import pisces from '@/assets/images/pisces@2x.png';

export default {
  name: 'Horoscope',
  data() {
    return {
      horoscope: null,
      taurus,
      aries,
      gemini,
      cancer,
      leo,
      virgo,
      libra,
      scorpio,
      sagittarius,
      capricorn,
      aquarius,
      pisces,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const response = await this.$request.get(`/api/guide/horoscope/${this.$route.params.horoscope}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.data.success) {
        this.$router.replace('/');
      } else {
        this.horoscope = response.data.result;
      }
    },
  },
  computed: {
    img() {
      return this[this.horoscope.zodiac.alias];
    },
  },
};
</script>
